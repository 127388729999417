<template>
  <div>
    <VTitle :title="$t('app.memberships', 2)" class="section-title" />

    <div class="grid grid-cols-2 gap-5 mt-10">
      <div>
        <Roles
          :memberships="user.roles"
          @click:add="$emit('create:role', $event)"
          @click:confirm="onClickConfirm($event, 'role')"
        />
      </div>

      <div>
        <Departments
          :memberships="user.departments"
          @click:add="$emit('create:department', $event)"
          @click:confirm="onClickConfirm($event, 'department')"
        />

        <Sites
          class="mt-5"
          :memberships="user.sites"
          @click:add="$emit('create:site', $event)"
          @click:confirm="onClickConfirm($event, 'site')"
        />
      </div>

      <DeleteModal
        v-if="isModalVisible"
        id="delete-membership-modal"
        :message="message"
        :is-deleting="isDeleting"
        @click:cancel="onClickCancel"
        @click:delete="
          $emit(`delete:${membershipType}`, {
            deletable,
            hideModal
          })
        "
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useModal from "@/composables/useModal";
// Components
import Departments from "./Departments";
import Roles from "./Roles";
import Sites from "./Sites";
import DeleteModal from "@/components/modals/DeleteModal";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VTitle,
    Departments,
    Roles,
    Sites,
    DeleteModal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();

    // Composables
    const { isModalVisible, showModal, hideModal } = useModal(
      "#delete-membership-modal"
    );
    const isDeleting = inject("isDeleting"); // @NOTE: Provided by User

    // DATA
    const membershipType = ref("");
    const deletable = ref({});

    // COMPUTED
    const name = computed(() => {
      const { firstname, lastname } = props.user ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });
    const documentTitle = computed(() => {
      const documentTitle = `${t("app.memberships")} - ${t(
        "app.users",
        2
      )} - ${t("app.administration")}`;

      return name.value ? `${name.value} - ${documentTitle}` : documentTitle;
    });
    const message = computed(() => {
      return t("app.delete_relationship", {
        name: deletable.value?.name,
        relationship: name.value
      });
    });

    // METHODS
    const onClickConfirm = (item, type) => {
      deletable.value = item;
      membershipType.value = type;
      showModal();
    };

    return {
      message,
      name,
      isDeleting,
      membershipType,
      deletable,
      documentTitle,
      onClickConfirm,
      // useModal
      isModalVisible,
      showModal,
      onClickCancel: hideModal,
      hideModal
    };
  }
};
</script>
